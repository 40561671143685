<div 
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="config-modal" 
    tabindex="-1" 
    aria-labelledby="config-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Serial numbers</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                        <button 
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i 
                                class="fas ml-2 mr-2" 
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-3">
                <div class="row">
                    <div class="col">
                        <div class="row mb-3" *ngIf="USER.role === userRoles.iscManager || USER.role === userRoles.IscSupervisor|| USER.role === userRoles.isc">
                            <div class="col-3 field">
                                <label>Model No.<span class="required-asterik">*</span></label>
                                <ejs-textbox
                                    placeholder="Model Number"
                                    (change)="this.model = $event.value"
                                ></ejs-textbox>
                                <div class="field-error" *ngIf="validationApplied && !this.model">Model number is required</div>
                            </div>
                            <div class="col-3 field">
                                <label>Serial No.<span class="required-asterik">*</span></label>
                                <ejs-textbox
                                    placeholder="Serial Number"
                                    (change)="this.srNumber = $event.value"
                                ></ejs-textbox>
                                <div class="field-error" *ngIf="validationApplied && !this.srNumber">Serial number is required</div>
                            </div>
                            <div class="col-1">
                                <button class="btn btn-sm btn-success" (click)="getSerialNumbers($event)"> Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <ejs-grid 
                            *ngIf="mainGrid"
                            #serviceOrderGrid
                            allowPaging = "true"
                            [query]="query"
                            [pageSettings]="mainGrid.pageSettings"
                            [dataSource]="data" 
                            [allowFiltering]="true"
                            [allowSorting]="true" 
                            [allowResizing]="true" 
                            [allowReordering]="true" 
                            [toolbar]="mainGrid?.toolbar"
                            [filterSettings]="{ type: 'Menu' }"
                            [allowSelection]='true'
                            (toolbarClick)="toolbarClick($event, serviceOrderGrid)"
                            (commandClick)="onCommandClick($event)"
                            (rowSelected)='rowSelected($event)'
                            (created)="onGridCreated($event)"
                            (actionBegin)="onGridActionBegin($event)"
                            (actionComplete)="onGridActionComplete($event, serviceOrderGrid)"
                            >
                            <e-columns>
                                <e-column 
                                    *ngFor="let column of mainGrid.columns;" 
                                    [field]="column.field" 
                                    [headerText]="column.headerText" 
                                    [clipMode]="'EllipsisWithTooltip'"
                                    [type]="column.type"
                                    [width]="column.width"
                                    [format]="column.format ? column.format : null"
                                    [filterType]="'contains'"
                                    [textAlign]="column.textAlign" 
                                    [visible]="column.visible"
                                    [commands]="column?.commands ? column.commands : null"
                                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                                    [allowEditing]="column.allowEditing" 
                                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>