export const environment = {
    production: false,
    redirectUrl: 'https://sso1beta.snapon.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DMobileApp.js%26redirect_uri%3Dhttps%253A%252F%252Fmobileappdevtest-designertest.azurewebsites.net%252Fauth%26response_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520Roles%26state%3D16414008400430.ruvtjl5vfu%26nonce%3D16414008400430.usnkl95geh',
    loginUrl: 'https://test-idp-rims.inavista.com/api/',
    apiUrl: 'https://test-api-01-rims.inavista.com/api/',
    loginAppUrl: 'https://test.accounts.inavista.com/',
    webAuthUrl: 'https://test-webauth.inavista.com/api/',
    imageUrl: 'https://mobileappstorageacc.blob.core.windows.net/test/snapon/mobileservice/images/',
    HPFInterface: 'https://www.chasepaymentechhostedpay-var.com/hpf/1_1',
    barclaysFormURL: 'https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp',
};
