import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { CommonService } from '../../services/common.service';
import { serviceOrderTypes } from 'src/app/modules/service-order/models/service-order.model';

@Component({
  selector: 'app-service-order-nte-update-modal',
  templateUrl: './service-order-nte-update-modal.component.html',
  styleUrls: ['./service-order-nte-update-modal.component.scss']
})
export class ServiceOrderNteUpdateModalComponent implements OnInit {

  @Input() data: any;
  @Output() closeNTEPopup: EventEmitter<any>;
  @ViewChild('nteUpdateForm') nteUpdateForm: NgForm;
  nteAmount: number

  constructor(private serviceOrderService: ServiceOrderService, private commonService: CommonService) {
    this.closeNTEPopup = new EventEmitter();
  }

  ngOnInit() {}

  saveNTEAmount() {
    if(this.nteUpdateForm.valid && this.nteAmount != 0){
      this.serviceOrderService.updateServiceOrderNTE(this.data.serviceOrderId, this.nteAmount, this.data.serviceOrderType === serviceOrderTypes.isc)
      .subscribe((response: any) => {
        if(response.isSuccessful) {
          this.commonService.showNotification('success',response.message);
          this.closeNTEPopup.emit('success');
        }
      })
    }
  }
}
